import React from "react"
import SEO from "../components/seo"

const Tags = () => (
  <>
    <SEO title="Tags" />
    <h1>Tags</h1>
  </>
)

export default Tags
